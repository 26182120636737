import { useLocale } from 'hooks';
import { useMemo } from 'react';
import { BOATAROUND_WEB_URL } from 'utils/constants/environment';
import { languageCode } from 'utils/i18n';
import trimLeadingSlash from 'utils/trimLeadingSlash';

const transformMap: { [key: string]: string } = {
  'en-GB': '',
  'cs-CZ': 'cz',
  'el-GR': 'gr',
};

export default function useBoataroundWebUrl(
  route?: string,
  localized: boolean = true
) {
  const { locale } = useLocale();

  return useMemo(() => {
    const boataroundLocale = transformMap.hasOwnProperty(locale)
      ? transformMap[locale]
      : languageCode(locale);
    const parts = [BOATAROUND_WEB_URL];

    if (localized) {
      parts.push(boataroundLocale);
    }

    if (route) {
      parts.push(trimLeadingSlash(route));
    }

    return parts.filter(Boolean).join('/');
  }, [locale, localized, route]);
}
