import withCharterSegment from 'utils/url/withCharterSegment';

const ROUTE_HOME_FALLBACK = '/';
const ROUTE_DASHBOARD = withCharterSegment('/');
const ROUTE_ORDER = withCharterSegment('/orders/:id');
const ROUTE_ORDER_CREW_LIST = withCharterSegment('/orders/:id/crew-list');
const ROUTE_ORDERS = withCharterSegment('/orders');
const ROUTE_AVAILABILITY = withCharterSegment('/availability');
const ROUTE_DISCOUNTS = withCharterSegment('/promotions');
const ROUTE_DISCOUNT_CREATE = withCharterSegment('/promotions/create');
const ROUTE_DISCOUNT_EDIT = withCharterSegment('/promotions/:id');
const ROUTE_FLEET = withCharterSegment('/fleet');
const ROUTE_FLEET_CREATE = withCharterSegment('/fleet/create/:draftId?');
const ROUTE_FLEET_EDIT = withCharterSegment('/fleet/:boatId');
const ROUTE_FLEET_CHANGE_REQUEST = withCharterSegment(
  '/fleet/:boatId/change-requests/:changeRequestId'
);
const ROUTE_FLEET_CHANGE_REQUESTS = withCharterSegment(
  '/fleet/:boatId/change-requests'
);
const ROUTE_DETAILS = withCharterSegment('/details');
const ROUTE_BASES = withCharterSegment('/bases');
const ROUTE_BASE_CREATE = withCharterSegment('/bases/create');
const ROUTE_BASE_EDIT = withCharterSegment('/bases/:baseId');
const ROUTE_BASE_CHANGE_REQUEST = withCharterSegment(
  '/bases/:baseId/change-requests/:changeRequestId'
);
const ROUTE_BASE_CHANGE_REQUESTS = withCharterSegment(
  '/bases/:baseId/change-requests'
);
const ROUTE_CHANGE_REQUEST = withCharterSegment(
  '/change-requests/:changeRequestId'
);
const ROUTE_CHANGE_REQUESTS = withCharterSegment('/change-requests');
const ROUTE_INVOICES = withCharterSegment('/invoices');
const ROUTE_CONTRACT = withCharterSegment('/your-agreement/:id');
const ROUTE_CONTRACTS = withCharterSegment('/your-agreement');
const ROUTE_GENERAL_TERMS = withCharterSegment('/general-delivery-terms');
const ROUTE_LOGIN = '/login';
const ROUTE_CHARTER_LIST = '/charter-list';
const ROUTE_MARKETING_ASSETS = '/marketing-assets';

export {
  ROUTE_HOME_FALLBACK,
  ROUTE_DASHBOARD,
  ROUTE_ORDER,
  ROUTE_ORDER_CREW_LIST,
  ROUTE_ORDERS,
  ROUTE_AVAILABILITY,
  ROUTE_DISCOUNTS,
  ROUTE_DISCOUNT_CREATE,
  ROUTE_DISCOUNT_EDIT,
  ROUTE_FLEET,
  ROUTE_FLEET_CREATE,
  ROUTE_FLEET_EDIT,
  ROUTE_FLEET_CHANGE_REQUEST,
  ROUTE_FLEET_CHANGE_REQUESTS,
  ROUTE_DETAILS,
  ROUTE_BASES,
  ROUTE_BASE_CREATE,
  ROUTE_BASE_EDIT,
  ROUTE_BASE_CHANGE_REQUEST,
  ROUTE_BASE_CHANGE_REQUESTS,
  ROUTE_CHANGE_REQUEST,
  ROUTE_CHANGE_REQUESTS,
  ROUTE_INVOICES,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTS,
  ROUTE_GENERAL_TERMS,
  ROUTE_LOGIN,
  ROUTE_CHARTER_LIST,
  ROUTE_MARKETING_ASSETS,
};
