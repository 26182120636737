import { TFunction } from 'react-i18next';
import { lazy, ComponentType } from 'react';
import {
  ROUTE_AVAILABILITY,
  ROUTE_DISCOUNTS,
  ROUTE_DISCOUNT_CREATE,
  ROUTE_DISCOUNT_EDIT,
  ROUTE_DASHBOARD,
  //ROUTE_DETAILS,
  ROUTE_FLEET,
  ROUTE_FLEET_CREATE,
  ROUTE_FLEET_EDIT,
  ROUTE_FLEET_CHANGE_REQUEST,
  ROUTE_FLEET_CHANGE_REQUESTS,
  ROUTE_LOGIN,
  ROUTE_CHARTER_LIST,
  ROUTE_ORDER,
  ROUTE_ORDER_CREW_LIST,
  ROUTE_ORDERS,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTS,
  ROUTE_GENERAL_TERMS,
  ROUTE_HOME_FALLBACK,
  ROUTE_BASE_CREATE,
  ROUTE_BASE_EDIT,
  ROUTE_BASES,
  ROUTE_BASE_CHANGE_REQUEST,
  ROUTE_BASE_CHANGE_REQUESTS,
  ROUTE_CHANGE_REQUESTS,
  ROUTE_INVOICES,
  ROUTE_CHANGE_REQUEST,
  ROUTE_MARKETING_ASSETS,
} from 'utils/constants/routes';
import localizeRoutes from 'utils/i18n/localizeRoutes';

const DashboardPage = lazy(() => import('views/Dashboard/DashboardPage'));
const OrderPage = lazy(() => import('views/Order/OrderPage/OrderPage'));
const OrderCrewListPage = lazy(
  () => import('views/Order/OrderCrewListPage/OrderCrewListPage')
);
const OrdersPage = lazy(() => import('views/Order/OrdersPage'));
const AvailabilityPage = lazy(
  () => import('views/Availability/AvailabilityPage')
);
const DiscountsPage = lazy(() => import('views/Discount/DiscountsPage'));
const DiscountCreatePage = lazy(
  () => import('views/Discount/DiscountCreatePage/DiscountCreatePage')
);
const DiscountEditPage = lazy(
  () => import('views/Discount/DiscountEditPage/DiscountEditPage')
);
const FleetPage = lazy(() => import('views/Fleet/FleetPage'));
const FleetCreatePage = lazy(
  () => import('views/Fleet/FleetCreatePage/FleetCreatePage')
);
const FleetEditPage = lazy(
  () => import('views/Fleet/FleetEditPage/FleetEditPage')
);
//const DetailsPage = lazy(() => import('views/Details/DetailsPage'));
const BasesPage = lazy(() => import('views/Base/BasesPage/BasesPage'));
const BaseCreatePage = lazy(
  () => import('views/Base/BaseCreatePage/BaseCreatePage')
);
const BaseEditPage = lazy(() => import('views/Base/BaseEditPage/BaseEditPage'));
const ChangeRequestsPage = lazy(
  () => import('views/ChangeRequest/ChangeRequestsPage/ChangeRequestsPage')
);
const ChangeRequestPage = lazy(
  () => import('views/ChangeRequest/ChangeRequestPage/ChangeRequestPage')
);
const InvoicesPage = lazy(
  () => import('views/Invoice/InvoicesPage/InvoicesPage')
);
const ContractPage = lazy(
  () => import('views/Contract/ContractPage/ContractPage')
);
const ContractsPage = lazy(() => import('views/Contract/ContractsPage'));
const GeneralTermsPage = lazy(
  () => import('views/GeneralTerms/GeneralTermsPage')
);
const LoginPage = lazy(() => import('views/Login/LoginPage'));
const CharterListPage = lazy(
  () => import('views/CharterSelection/CharterChoicePage')
);
const MarketingAssetsPage = lazy(
  () => import('views/MarketingAssets/MarketingAssetsPage')
);

const AvailabilitySettingsProvider = lazy(
  () => import('contexts/AvailabilitySettingsContext')
);

export type Route = {
  path: string;
  pathBack?: string;
  component: ComponentType;
  exact?: boolean;
  private?: boolean;
  title: string;
  contextProviders?: ComponentType[]; // providers are wrapping component according to their order - the first element is the outermost parent
};

export default function routesFactory(t: TFunction) {
  const routes: Route[] = [
    {
      // fallback for / route when user is not logged to be able redirect to login
      path: ROUTE_HOME_FALLBACK,
      component: DashboardPage,
      private: true,
      exact: true,
      title: t('Dashboard'),
    },
    {
      path: ROUTE_DASHBOARD,
      component: DashboardPage,
      private: true,
      exact: true,
      title: t('Dashboard'),
    },
    {
      path: ROUTE_ORDER,
      pathBack: ROUTE_ORDERS,
      component: OrderPage,
      private: true,
      exact: true,
      title: t('Booking'),
    },
    {
      path: ROUTE_ORDER_CREW_LIST,
      pathBack: ROUTE_ORDER,
      component: OrderCrewListPage,
      private: true,
      exact: true,
      title: t('Crew list'),
    },
    {
      path: ROUTE_ORDERS,
      component: OrdersPage,
      private: true,
      exact: true,
      title: t('Bookings'),
    },
    {
      path: ROUTE_AVAILABILITY,
      component: AvailabilityPage,
      private: true,
      exact: true,
      title: t('Availability'),
      contextProviders: [AvailabilitySettingsProvider],
    },
    {
      path: ROUTE_DISCOUNTS,
      component: DiscountsPage,
      private: true,
      exact: true,
      title: t('Promotions'),
    },
    {
      path: ROUTE_DISCOUNT_CREATE,
      pathBack: ROUTE_DISCOUNTS,
      component: DiscountCreatePage,
      private: true,
      exact: true,
      title: t('Create promotion'),
    },
    {
      path: ROUTE_DISCOUNT_EDIT,
      pathBack: ROUTE_DISCOUNTS,
      component: DiscountEditPage,
      private: true,
      exact: true,
      title: t('Edit promotion'),
    },
    {
      path: ROUTE_FLEET,
      component: FleetPage,
      private: true,
      exact: true,
      title: t('Fleet'),
    },
    {
      path: ROUTE_FLEET_CREATE,
      pathBack: ROUTE_FLEET,
      component: FleetCreatePage,
      private: true,
      exact: true,
      title: t('Create boat'),
    },
    {
      path: ROUTE_FLEET_EDIT,
      pathBack: ROUTE_FLEET,
      component: FleetEditPage,
      private: true,
      exact: true,
      title: t('Edit boat'),
    },
    {
      path: ROUTE_FLEET_CHANGE_REQUEST,
      pathBack: ROUTE_FLEET_CHANGE_REQUESTS,
      component: ChangeRequestPage,
      private: true,
      exact: true,
      title: t('Change request'),
    },
    {
      path: ROUTE_FLEET_CHANGE_REQUESTS,
      pathBack: ROUTE_FLEET_EDIT,
      component: ChangeRequestsPage,
      private: true,
      exact: true,
      title: t('Change requests'),
    },
    /*{
      path: ROUTE_DETAILS,
      component: DetailsPage,
      private: true,
      exact: true,
      title: t('Dashboard'),
    },*/
    {
      path: ROUTE_BASES,
      component: BasesPage,
      private: true,
      exact: true,
      title: t('Bases'),
    },
    {
      path: ROUTE_BASE_CREATE,
      pathBack: ROUTE_BASES,
      component: BaseCreatePage,
      private: true,
      exact: true,
      title: t('Create base'),
    },
    {
      path: ROUTE_BASE_EDIT,
      pathBack: ROUTE_BASES,
      component: BaseEditPage,
      private: true,
      exact: true,
      title: t('Edit base'),
    },
    {
      path: ROUTE_BASE_CHANGE_REQUEST,
      pathBack: ROUTE_BASE_CHANGE_REQUESTS,
      component: ChangeRequestPage,
      private: true,
      exact: true,
      title: t('Change request'),
    },
    {
      path: ROUTE_BASE_CHANGE_REQUESTS,
      pathBack: ROUTE_BASE_EDIT,
      component: ChangeRequestsPage,
      private: true,
      exact: true,
      title: t('Change requests'),
    },
    {
      path: ROUTE_CHANGE_REQUEST,
      pathBack: ROUTE_CHANGE_REQUESTS,
      component: ChangeRequestPage,
      private: true,
      exact: true,
      title: t('Change request'),
    },
    {
      path: ROUTE_CHANGE_REQUESTS,
      component: ChangeRequestsPage,
      private: true,
      exact: true,
      title: t('Change requests'),
    },
    {
      path: ROUTE_INVOICES,
      component: InvoicesPage,
      private: true,
      exact: true,
      title: t('Invoices'),
    },
    {
      path: ROUTE_CONTRACT,
      pathBack: ROUTE_CONTRACTS,
      component: ContractPage,
      private: true,
      exact: true,
      title: t('Agreement'),
    },
    {
      path: ROUTE_CONTRACTS,
      component: ContractsPage,
      private: true,
      exact: true,
      title: t('Agreement'),
    },
    {
      path: ROUTE_GENERAL_TERMS,
      pathBack: ROUTE_CONTRACTS,
      component: GeneralTermsPage,
      private: true,
      exact: true,
      title: t('General terms and conditions'),
    },
    {
      path: ROUTE_LOGIN,
      component: LoginPage,
      exact: true,
      title: t('Sign in'),
    },
    {
      path: ROUTE_CHARTER_LIST,
      component: CharterListPage,
      private: true,
      exact: true,
      title: t('Charter company'),
    },
    {
      path: ROUTE_MARKETING_ASSETS,
      component: MarketingAssetsPage,
      private: true,
      exact: true,
      title: t('Marketing assets'),
    },
  ];

  return localizeRoutes(routes);
}
