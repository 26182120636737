import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import detectLocaleFromPath from 'utils/i18n/detectLocaleFromPath';
import locales from './i18n/locales';

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(
        /* webpackChunkName: "locale" */
        `./i18n/locales/${language}/${namespace}.json`
      )
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(initReactI18next)
  .init({
    lng: detectLocaleFromPath(),
    supportedLngs: locales,
    fallbackLng: false,

    // Define the namespaces
    ns: ['translations', 'routes'],

    // Default namespace remains 'translations'
    defaultNS: 'translations',

    // return key when there is untranslated string
    returnEmptyString: false,

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on('languageChanged', () => {
  document.documentElement.dir = i18n.dir();
});

export default i18n;
